import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../organisms/layout';
import LinkCard from '../molecules/link-card';
import { SiteMeta } from '../atoms/site-meta';
import TitledCard from '../atoms/titled-card';
import TagCloud from '../organisms/tag-cloud';
import PathUtils from '../../utils/path-utils';
import { Box } from '@mui/material';
// import { GatsbyImage } from 'gatsby-plugin-image';
import Img from 'gatsby-image';
import { TagsTemplateQuery } from '../../../types/graphql-types';
import Adsense from '../molecules/adsense';

const TagsTemplate = (props: {
  pageContext: {
    tag: string;
  };
}) => (
  <StaticQuery
    query={graphql`
      query TagsTemplate {
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              excerpt(truncate: true)
              frontmatter {
                title
                tags
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                    }
                  }
                }
                thumb {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: TagsTemplateQuery) => {
      let edges = data.allMarkdownRemark.edges;
      edges.sort(
        (x, y) =>
          (PathUtils.slugToDateName(y.node?.fields?.slug ?? '').date?.getTime() ?? 0) -
          (PathUtils.slugToDateName(x.node?.fields?.slug ?? '').date?.getTime() ?? 0)
      );

      return (
        <Layout
          asides={[
            <TitledCard title="タグクラウド" key="tag-cloud">
              <TagCloud />
            </TitledCard>,
          ]}
          asidesSticky={[<Adsense />]}
        >
          <SiteMeta />

          <Box sx={{ listStyleType: 'none', m: 0, display: 'grid', gap: 2 }}>
            {edges
              .filter(x => (x.node?.frontmatter?.tags ?? []).some(y => y === props.pageContext.tag))
              .map(edge => {
                const post = edge.node!;
                const title = post.frontmatter?.title ?? post.fields?.slug ?? '';
                const dateName = PathUtils.slugToDateName(post.fields?.slug ?? '');
                return (
                  <LinkCard
                    key={dateName.name}
                    title={title}
                    to={'/' + dateName.name}
                    // image={
                    //   post?.frontmatter?.thumb ? (
                    //     <GatsbyImage image={post.frontmatter.thumb.childImageSharp?.gatsbyImageData} alt={title} />
                    //   ) : (
                    //     post?.frontmatter?.image && <GatsbyImage image={post.frontmatter.image.childImageSharp?.gatsbyImageData} alt={title} />
                    //   )
                    // }

                    image={
                      post.frontmatter?.thumb ? (
                        <Img style={{ width: '100%', height: '100%' }} fluid={post.frontmatter?.thumb?.childImageSharp?.fluid as any} alt={title} />
                      ) : (
                        post.frontmatter?.image && (
                          <Img style={{ width: '100%', height: '100%' }} fluid={post.frontmatter?.image?.childImageSharp?.fluid as any} alt={title} />
                        )
                      )
                    }
                    date={dateName.date}
                  >
                    <p dangerouslySetInnerHTML={{ __html: post.excerpt ?? '' }} itemProp="description" />
                  </LinkCard>
                );
              })}
          </Box>
        </Layout>
      );
    }}
  />
);

export default TagsTemplate;
